import React from 'react';
import HeroSection from '../components/HeroSection';
import Logo from '../components/Logo';

const Home = () => {
  return (
    <>
      <Logo />
      <HeroSection />
    </>
  );
};

export default Home;
